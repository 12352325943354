import request from '@/utils/request'

// 查询平台管理用户
export function findSystemUsers(params) {
  return request({
    url: `/system/users`,
    method: 'get',
    params: params
  })
}

// 查询用户
export function findUsers(params) {
  return request({
    url: `/users`,
    method: 'get',
    params: params
  })
}

// 创建用户
export function createUser(data) {
  return request({
    url: `/users`,
    method: 'post',
    data
  })
}

// 查询单个用户
export function findUser(userId) {
  return request({
    url: `/users/${userId}`,
    method: 'get'
  })
}

// 查询单个用户表单值
export function findUserForm(userId) {
  return request({
    url: `/users/${userId}/form`,
    method: 'get'
  })
}

// 查询登录用户基本信息
export function findUserBasicInfo(userId) {
  return request({
    url: `/users/${userId}/basic_info`,
    method: 'get'
  })
}

// 更新用户
export function updateUser(userId, data) {
  return request({
    url: `/users/${userId}`,
    method: 'put',
    data
  })
}

// 更新用户有效性
export function updateUserEffective(userId, data) {
  return request({
    url: `/users/${userId}/effective`,
    method: 'patch',
    data
  })
}

// 更新用户是否上班
export function updateUserIsWorking(userId, data) {
  return request({
    url: `/users/${userId}/is_working`,
    method: 'patch',
    data
  })
}

// 更新账户的姓名
export function updateUserSelfFullName(userId, data) {
  return request({
    url: `users/${userId}/full_name`,
    method: 'patch',
    data
  })
}

// 更新账户的密码
export function updateUserSelfPassword(userId, data) {
  return request({
    url: `/users/${userId}/password`,
    method: 'patch',
    data
  })
}

// 更新账户的绑定手机
export function updateUserSelfPhoneNumber(userId, data) {
  return request({
    url: `users/${userId}/phone_number`,
    method: 'patch',
    data
  })
}

// 验证手机唯一性
export function verificationPhoneNumber(data) {
  return request({
    url: `users/verification/phone_number`,
    method: 'post',
    data
  })
}

// 删除账号
export function deleteUser(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  })
}

// 获取财务下拉选项
export function findTenantFinanceOptions() {
  return request({
    url: `/users/finance/options`,
    method: 'get'
  })
}
// 获取行政下拉选项
export function findTenantAdministrativeOptions() {
  return request({
    url: `/users/administrative/options`,
    method: 'get'
  })
}

// 获取总经理下拉选项
export function findTenantManagerOptions() {
  return request({
    url: `/users/manager/options`,
    method: 'get'
  })
}

// 获取租户下拉选项
export function findTenantOptions() {
  return request({
    url: `/users/tenant/options`,
    method: 'get'
  })
}

// 获取用户下拉选项
export function findTenantUserOptions() {
  return request({
    url: `/tenant/users/options`,
    method: 'get'
  })
}

// 获取目标员工选项
export function findTenantTargetUserOptions() {
  return request({
    url: `/tenant/users/target/options`,
    method: 'get'
  })
}

// 获取用户下拉选项（含管理员）
export function findTenantUserWithAdminOptions() {
  return request({
    url: `/tenant/users/with_admin/options`,
    method: 'get'
  })
}

// 获取有服务订单跟踪权限的用户下拉选项
export function findOrderAuthorityOptions(params) {
  return request({
    url: `/tenant/users/order_authority_options`,
    method: 'get',
    params: params
  })
}

// 查询任务负责人选项
export function findTenantTaskUserOptions(params) {
  return request({
    url: `/tenant/users/task/options`,
    method: 'get',
    params: params
  })
}

// 查询名下租户
export function findTenantUsers(params) {
  return request({
    url: `/tenant/users`,
    method: 'get',
    params: params
  })
}

// 获取用户下拉选项
export function finSystemUserOptions() {
  return request({
    url: `/system/users/options`,
    method: 'get'
  })
}

// 获取用户下拉选项
export function finSystemUserWithoutAdminOptions(params) {
  return request({
    url: `/system/users/without_admin/options`,
    method: 'get'
  })
}

export function findTenantServiceOptions(params) {
  return request({
    url: `/tenant/users/service/options`,
    method: 'get',
    params: params
  })
}

export function findFuneralHomeOptions(params) {
  return request({
    url: `/tenant/users/funeral_home/options`,
    method: 'get',
    params: params
  })
}
